<template>
  <div>
    <CCard body-wrapper>
      <CDataTable border stripe sorter :items="eventList" :fields="fieldsEventList" :clickableRows="false" :hover="false" :sorterValue="{ column: 'startAt', asc: true }" class="text-center">
        <template #index="data">
          <td>
            {{ data.index + 1 }}
          </td>
        </template>
        <template #startAt="{item}">
          <td>
            {{ timeThaiFormat(item.startAt) }}
          </td>
        </template>
        <template #status="{item}">
          <td>
              <h3 :class="getStatusTextColor(item['status_tag'])">
                {{ getStatusText(item["status_text"]) }} 
              </h3>
          </td>
        </template>
        <template v-slot:id="data">
          <td>
            <CButton size="md" color="primary" style="width: 100%" v-on:click="gotoEvent(data['item']['_id'])">
              <b>Go to event</b>
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import examManangerApi from "@/services/apiServices"
import moment from 'moment-timezone'

export default {
  name: "Eventlist",
  components: {
  },
  data() {
    return {
      selected: "Table",
      isHovering: false,
      eventList: [],
      adminList: ["nuttapong.pongkam@cmu.ac.th", "thapakorn.pe@cmu.ac.th"],
      bgColor: "#778899",
      position: "bottom-right",
      fieldsEventList: [
        { key: "eventName", label: "Event Name", _style: "width:30%" },
        { key: "startAt", label: "Event Start Time ", _style: "width:20%" },
        { key: "status", label: "Status", _style: "width:20%" },
        { key: "id", label: "Action", _style: "width:20%" },
      ],
      isAdmin: false,
    }
  },
  created() {
    this.getEventListByOwner(localStorage.getItem("email"))
  },
  methods: {
    getStatusText (status) {
      switch (status) {
        case "inprogress":
          return "In progress"
        case "incoming":
          return "Not started"
        default:
          return "Not started"
      }
    },
    getStatusTextColor (status) {
      switch (status) {
        case "inprogress":
          return "text-success"
        case "incoming":
          return "text-warning"
        default:
          return "text-primary"
      }
    },
    async getEventListByOwner(email) {
      let timeNow = moment.tz(new Date(), "Asia/Bangkok").unix()
      let eventList = await examManangerApi.getEventListByOwner(email)
      if (eventList["status"]) {
        this.eventList = []
        for (let event of eventList["data"]) {
          if(event['startAt'] < timeNow && event['endAt'] > timeNow){
            event["status_text"] = "inprogress"
            event["status_tag"] = "inprogress"
            event["status_color"] = "inprogress"
            this.eventList.push(event)
          }
          else if (event['startAt'] > timeNow){
            event["status_text"] = "incoming"
            event["status_tag"] = "incoming"
            event["status_color"] = "incoming"
            this.eventList.push(event)
          }
        }
      }
    },
    redirectToEventTable(eventOnClick) {
      window.open(`events/${eventOnClick["_id"]}/detail`, "_blank").focus()
    },
    gotoEvent(eventId) {
      window.open(`events/${eventId}/detail`, "_blank").focus()
    },
    timeThaiFormat(timestamp) {
      let option = { year: "numeric", month: "long", day: "numeric", weekday: "long", hour: "numeric", minute: "numeric", second: "numeric" }
      return new Date(parseInt(timestamp) * 1000).toLocaleString("th-TH", option)
    },
    async getCountDownTime(timestamp) {
      console.log(timestamp)
      return `อีก 1 ชม 6 นาที`
    },
    getColorOfStatus(tag) {
      console.log("tag " , tag)
      switch (tag) {
        case "incoming":
          return "warning"
        case "inprogress":
          return "success"
        default:
          return "primary"
      }
    },
  },
}
</script>
<style scoped>
.text-dark {
  color: #032539 !important;
}
.text-white {
  color: aliceblue !important;
}
</style>
